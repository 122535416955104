import { lazy } from "react";
import { Navigate } from "react-router-dom";
import RouteGenerator from "../views/features/RouteGenerator/RouteGenerator.js";
import ModelAndSchemaGenerator from "../views/features/ModelSchemaGenerator/ModelSchemaGenerator.js";
import AggregationGenerator from "../views/features/AggregationGenerator/AggregationGenerator.jsx";
import BashGenerator from "../views/features/BashGenerator/BashGenerator.js"
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1.js"));

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="home" /> },
      { path: "home", exact: true, element: <Dashboard1 /> },
      { path: "features/bash-generator", exact: true, element: <BashGenerator /> },
      { path: "features/route-generator", exact: true, element: <RouteGenerator /> },
      { path: "features/model-schema-generator", exact: true, element: <ModelAndSchemaGenerator /> },
      { path: "features/aggregation-generator", exact: true, element: <AggregationGenerator /> },
    ],
  },
];

export default ThemeRoutes;
