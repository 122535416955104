import React from "react";
import { Box, Chip } from "@mui/material";

const getChipName = (type) => {
  if (type === 'nginx_conf_file') {
    return 'Nginx Conf file for react app';
  }
  if (type === 'gitHub_workflow_file') {
    return 'GitHub Workflow for Production Build';
  }
};

const CommandTypeSelector = ({ commandType, setCommandType, validOptions }) => (
  <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 3 }}>
    {validOptions.map((type) => (
      <Chip
        key={type}
        label={getChipName(type)}
        onClick={() => setCommandType(type)}
        color={commandType === type ? "primary" : "default"}
        sx={{ cursor: "pointer" }}
      />
    ))}
  </Box>
);

export default CommandTypeSelector;
