import React, { useState } from "react";
import CommandTypeSelector from './components/CommandTypeSelector'
import GitHubWorkflowGenerator from './components/GithubWorkflowGenerator'
import NginxConfigGenerator from './components/NginxConfigGenerator'
import { Box, IconButton, Typography } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const VALID_OPTIONS = ["nginx_conf_file", "gitHub_workflow_file"];

const BashGenerator = () => {
  const [commandType, setCommandType] = useState(VALID_OPTIONS[0]);
  const [additionalParams, setAdditionalParams] = useState({});
  const [copied, setCopied] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdditionalParams((prev) => ({ ...prev, [name]: value }));
  };

  const generateCommand = () => {
    switch (commandType) {
      case "nginx_conf_file":
        return `server {\n  root /var/www/${additionalParams.folderName}/build;\n  server_name ${additionalParams.domainName || 'projects-maintenance.demobykiranpal.in'};\n  index index.html index.htm;\n  location / {\n    root /var/www/${additionalParams.folderName}/build;\n    try_files $uri /index.html;\n  }\n}`;
      case "gitHub_workflow_file":
        return `name: Production Build\n\non:\n  push:\n    branches:\n        - master\n\njobs:\n  deploy:\n    runs-on: ubuntu-latest\n\n    steps:\n     - name: SSH deploy\n       uses: appleboy/ssh-action@master\n       with:\n          host: ${additionalParams.serverIP || '194.164.51.157'}\n          username: ${additionalParams.username || 'root'}\n          key: $ {{ secrets.KEY }}\n          port: 22\n          script: |\n               cd /var/www/projects-maintenance\n               git stash\n               git pull origin master\n`;
      default:
        return "";
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <Box sx={{ p: 2, maxWidth: "100%", margin: "auto", textAlign: "center" }}>
      <CommandTypeSelector commandType={commandType} setCommandType={setCommandType} validOptions={VALID_OPTIONS} />
      
      {commandType === "nginx_conf_file" && (
        <NginxConfigGenerator additionalParams={additionalParams} handleInputChange={handleInputChange} />
      )}

      {commandType === "gitHub_workflow_file" && (
        <GitHubWorkflowGenerator additionalParams={additionalParams} handleInputChange={handleInputChange} />
      )}

      {commandType && (
        <Box sx={{ mt: 3 }}>
          <Box sx={{ position: "relative" }}>
            <SyntaxHighlighter
              language="bash"
              style={materialDark}
              customStyle={{
                padding: "1em",
                borderRadius: "8px",
              }}
            >
              {generateCommand()}
            </SyntaxHighlighter>
            <CopyToClipboard text={generateCommand()} onCopy={handleCopy}>
              <IconButton sx={{ position: "absolute", top: 10, right: 10 }}>
                <ContentCopyIcon color={copied ? "success" : "primary"} />
              </IconButton>
            </CopyToClipboard>
          </Box>
        </Box>
      )}

      {copied && (
        <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
          Command copied!
        </Typography>
      )}
    </Box>
  );
};

export default BashGenerator;
