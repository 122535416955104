import React from "react";
import { TextField } from "@mui/material";

const NginxConfigGenerator = ({ additionalParams, handleInputChange }) => (
  <>
    <TextField
      label="Folder Name"
      variant="outlined"
      fullWidth
      name="folderName"
      value={additionalParams.folderName || ""}
      onChange={handleInputChange}
      sx={{ mb: 2 }}
    />
    <TextField
      label="Domain Name"
      variant="outlined"
      fullWidth
      name="domainName"
      value={additionalParams.domainName || ""}
      onChange={handleInputChange}
      sx={{ mb: 2 }}
    />
  </>
);

export default NginxConfigGenerator;
