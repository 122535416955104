import React from "react";
import { TextField } from "@mui/material";

const GitHubWorkflowGenerator = ({ additionalParams, handleInputChange }) => (
  <>
    <TextField
      label="Server IP"
      variant="outlined"
      fullWidth
      name="serverIP"
      value={additionalParams.serverIP || ""}
      onChange={handleInputChange}
      sx={{ mb: 2 }}
    />
    <TextField
      label="Username"
      variant="outlined"
      fullWidth
      name="username"
      value={additionalParams.username || ""}
      onChange={handleInputChange}
      sx={{ mb: 2 }}
    />
    <TextField
      label="SSH Key Secret"
      variant="outlined"
      fullWidth
      name="sshKeySecret"
      value={additionalParams.sshKeySecret || ""}
      onChange={handleInputChange}
      sx={{ mb: 2 }}
    />
  </>
);

export default GitHubWorkflowGenerator;
